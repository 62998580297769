import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col, Image, Accordion, Card } from 'react-bootstrap'
import Header from "../../src/img/header.jpg"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/global.scss'

export default () => (
  <Layout>
    <Container className="position-relative d-md-flex justify-content-center align-items-center px-0 mb-5" fluid>
      <Image src={Header} fluid />
      <Container className="position-md-absolute text-center">
        <Row>
          <Col xs="12" className="d-none d-md-block">
            <h2 className="text-white mb-3">
              Dr. Ernstfried Hanisch
            </h2>
            <h1 className="text-white mb-0">
              FAQ
            </h1>
          </Col>
          <Col xs="12" className="d-md-none mt-5">
            <h2 className="mb-3">
              Dr. Ernstfried Hanisch
            </h2>
            <h1 className="mb-0">
              FAQ
            </h1>
          </Col>
        </Row>
      </Container>
    </Container>
    <Container>
      <Row className="mb-5">
        <Col xs="12">
          <h3 className="mb-4">
            FAQ - Wissenswerte informationen zum Thema meines Buches
          </h3>
          <p className="mb-4">
            Im Folgenden finden Sie einige zentrale Fragen, die in meinem Buch und häufig in meiner Therapie behandelt werden.
          </p>
        </Col>
        <Col xs="12">
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h4 className="mb-0">
                  Was sind menschliche Grundbedürfnisse?
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="mb-3">
                    Grundbedürfnisse bezeichnen, was jeder Mensch für ein gesundes Leben benötigt. Sie sind universell und dem Menschen angeboren. Im Laufe eines menschlichen Lebens erfahren sie eine individuelle Ausprägung durch persönliche Erfahrungen und durch das kulturelle Wertesystem. Ihre Befriedigung ist für eine gesunde Entwicklung unabdingbar. Dies hat die Gehirnforschung eindrucksvoll nachgewiesen: „Die frühkindliche Gehirnentwicklung leidet Schaden, wenn nicht eine konstante, liebevolle Bezugsperson Sicherheit, Zärtlichkeit, Anregung usw. gewährleistet.“
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/was-sind-menschliche-grundbeduerfnisse" >
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h4 className="mb-0">
                  Grundbedürfnisse in Kindheit und Jugend (Leseprobe aus meinem Buch)
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="mb-3">
                    Um die Bedeutung von erfüllten Grundbedürfnissen zu verstehen, betrachten wir einmal eine idealisierte Vorstellung von optimalen Entwicklungsbedingungen für einen heranwachsenden Menschen: Wenn wir geboren werden, benötigen wir zunächst einen sicheren Platz. Unsere Eltern oder andere konstante Bezugspersonen versorgen uns mit allem Notwendigen. Im Idealfall sind sie selbst in einer guten Verfassung, leben im Einklang mit ihren Bedürfnissen, unterstützen sich gegenseitig und gehen liebevoll miteinander um. Sie heißen uns willkommen, begegnen uns mit Liebe, sind da, wenn wir sie brauchen. Sie haben feine Antennen für die verschiedenen Nuancen unseres Geschreis und speisen uns nicht ab, wenn wir gestreichelt werden wollen.
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/grundbeduerfnisse-in-kindheit-und-jugend">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h4 className="mb-0">
                  7 menschliche Grundbedürfnisse
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="mb-3">
                  Unsere wichtigsten Grundbedürfnisse, die je nach Lebensalter unterschiedliche Bedeutung haben, sind ...
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/7-menschliche-grundbeduerfnisse">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <h4 className="mb-0">
                  Wie entstehen seelische Verletzungen? Welche Muster oder Schemata können daraus entstehen?
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="mb-3">
                    Wenn ein Mensch unter Bedingungen aufwachsen kann, wie bei Punkt 2 (Grundbedürfnisse in Kindheit und Jugend) beschrieben, und ihn/sie keine dramatischen Schicksalsschläge ereilen, kann er/sie sich zu einer stabilen, selbstbewussten Persönlichkeit entwickeln. Wurden aber zeitweise oder dauerhaft zentrale Bedürfnisse nicht erfüllt oder verletzt, kann das bleibende Spuren hinterlassen, ähnlich einer nicht verheilenden Wunde. Ein Kind, das bei seiner Geburt nicht willkommen war, das keine oder wenig liebevolle Zuwendung bekommen hat, das körperlich gezüchtigt oder sexuell missbraucht wurde, das sich gegenüber anderen oft zurückgesetzt fühlte, das von wichtigen Bezugspersonen verlassen wurde, an dem nur herumkritisiert wurde, dem man nichts zugetraut hat, das mit seinen Gefühlen und Bedürfnissen nicht ernst genommen wurde – um nur einige besonders belastende Beispiele zu nennen – nimmt Schaden und muss versuchen, damit irgendwie zurecht zu kommen. Wenn solche negativen Erinnerungen später wiederaufleben, geschieht dies durch die Berührung „wunder Punkte“, man fühlt sich wieder wie in der früheren Bedrohungssituation, hilflos, wütend, wertlos wie damals als Kind und reagiert entsprechen mit unterschiedlichen Maßnahmen, um sich zu schützen.
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/wie-entstehen-seelische-verletzungen">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <h4 className="mb-0">
                  Wie schütze ich mich vor seelischen Verletzungen?
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="mb-3">
                    Leider gibt es keinen sicheren Schutz vor seelischen Verletzungen. Allerdings haben wir als erwachsene Menschen bessere Mittel, uns zur Wehr zu setzen oder für die Erfüllung unserer Bedürfnisse einzutreten, als wir sie als Kind hatten. Waren früher vielleicht beispielsweise der Rückzug, der Wutausbruch, das Krankwerden, das Verstummen die einzigen Möglichkeiten, unsere Bedürfnisverletzung zum Ausdruck zu bringen, so kann man als Erwachsener lernen, seine Gefühle und Bedürfnisse so zu äußern, dass unser Gegenüber die Chance hat, uns zu verstehen und – falls er/sie will – darauf einzugehen.
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/wie-schuetze-ich-mich-vor-seelischen-verletzungen">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <h4 className="mb-0">
                  Was kann man tun, wenn ein wunder Punkt berührt ist?
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="mb-3">
                    Hier einige Empfehlungen zitiert aus meinem Buch als Orientierungshilfe. Sie konsequent umzusetzen bedarf allerdings einiger Übung.
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/was-kann-man-tun-wenn-ein-wunder-punkt-beruehrt-ist">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                <h4 className="mb-0">
                  Was haben verletzte Grundbedürfnisse mit Burnout zu tun?
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="mb-3">
                    Wenn zentrale menschliche Bedürfnisse unerfüllt bleiben, versuchen wir mit großer seelischer  Anstrengung weitere Verletzungen zu vermeiden oder auf Umwegen doch noch zu einer Befriedigung des Bedürfnisses zu kommen. So sind wir z.B. bemüht, alle an uns gerichteten Erwartungen zu erfüllen, nur um Anerkennung zu bekommen. Oder wir sind zu allen Menschen immer freundlich und nett, damit wir nicht ausgegrenzt werden und möglichst willkommen sind. Oder wir nehmen ungern Hilfe an, damit wir uns nicht abhängig machen.
                  </p>
                  <p className="text-right mb-0">
                    <Link to="/faq/was-haben-verletzte-grundbeduerfnisse-mit-burnout-zu-tun">
                      Weiterlesen >
                    </Link>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)
